import React from "react";
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

import { Button, Container, Grid, Header, Icon } from 'semantic-ui-react'

export default () => (
  <Layout>
    <SEO title='Dziękujemy' />
    <Grid centered>
      <Grid.Row style={{ paddingTop: '8rem', paddingBottom: '8rem' }}>
        <Grid.Column>
          <Container type='text'>
            <Header title='true'>Dziękujemy!</Header>
            <p>Zapoznamy się z Twoją wiadomością, i odpiszemy lub oddzwonimy niebawem.</p>
            <Link to={`/`}>
              <Button primary color="teal" style={{ marginTop: '4rem' }}>
                <Icon name='left chevron' />
                Powrót
              </Button>
            </Link>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
);